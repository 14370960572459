exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-access-js": () => import("./../../../src/pages/access.js" /* webpackChunkName: "component---src-pages-access-js" */),
  "component---src-pages-dayservice-js": () => import("./../../../src/pages/dayservice.js" /* webpackChunkName: "component---src-pages-dayservice-js" */),
  "component---src-pages-founder-js": () => import("./../../../src/pages/founder.js" /* webpackChunkName: "component---src-pages-founder-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-recruit-js": () => import("./../../../src/pages/recruit.js" /* webpackChunkName: "component---src-pages-recruit-js" */)
}

